import { useAuthModal } from "@auth/context/AuthModalContext";
import { EAuthFormType } from "@auth/types/auth.types";
import { CrafthuntBauGPTChat } from "@crafthunt-app/crafthunt-web-baugpt";
import { NAVIGATION_HEIGHT } from "@crafthunt-layout/BaseNavigation/BaseNavigation";
import { useWindowSize } from "@react-hookz/web";
import { EJourneyTypes } from "constants/journey.constants";
import { useAuth } from "features/auth/context/AuthContext";
import { TranslationDef } from "features/translations/types/translation.types";
import { useHasHydrated } from "hooks/useHasHydrated";
import { useTranslation } from "next-i18next";
import { Url } from "next/dist/shared/lib/router/router";
import Router, { useRouter } from "next/router";
import { convertLocaleForBackend } from "utils/language.helper";

import "@crafthunt-app/crafthunt-web-baugpt/dist/index.css";

type BauGPTProps = {
  locale: string;
};

const BauGPT = ({ locale }: BauGPTProps) => {
  const { ready, i18n } = useTranslation();
  const hasHydrated = useHasHydrated();
  const router = useRouter();
  const { setAuthModalSettings } = useAuthModal();
  const { accessToken } = useAuth();
  const size = useWindowSize();
  const height = size?.height;

  const handleOnSignup = () => {
    const newUrl: Url = {
      pathname: router.pathname,
      query: {
        ...router.query,
        fromJourney: EJourneyTypes.BAUGPT,
      },
    };
    Router.replace(newUrl, newUrl, { scroll: false, shallow: true });
    setAuthModalSettings({
      show: true,
      action: EAuthFormType.SIGN_UP,
    });
  };

  if (!hasHydrated || !ready) {
    return null;
  }

  const translations = i18n.options.resources[i18n.language][
    "common"
  ] as TranslationDef;

  return (
    <div
      className="bg-white"
      style={{
        height: height - NAVIGATION_HEIGHT,
      }}
    >
      <div className="relative mx-auto h-full max-w-7xl pb-4 md:px-4">
        <CrafthuntBauGPTChat
          jwt={accessToken}
          translations={translations}
          lang={convertLocaleForBackend(locale)}
          onSignup={handleOnSignup}
        />
      </div>
    </div>
  );
};

export default BauGPT;
